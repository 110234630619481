.head-pro1 {
    width: 100%;
    display: flex;
    justify-content: center;
}
.head-pro2 {
    width: 95%;
}
.head-pros {
    color:#F3622D;
    font-weight: 700;

    font-size: 20px;

    text-transform: uppercase;
}
.head-pro {
    color:#F3622D;
    font-weight: 700;
    text-align: center;
    font-size: 30px;
    padding: 50px 0px;
    text-transform: uppercase;
}
.pro-imggd>img{

    width: 500px;
    border-radius: 15px;
    height: 350px;
    object-fit: cover;
}
.head-pro3{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 360px;
    padding: 15px;
    display: flex;
    border-radius: 15px;
    gap: 14px;
    flex-direction: column;
    background: #ffffff3b;
}
.head-flexi{
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: center;
}