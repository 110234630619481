.tab-opp {
    background-color: transparent;
    border: 0;
    color: inherit;
    cursor: pointer;
    outline: 0 !important;
}

.logoss {
    width: 124px;
    object-fit: cover;
    height: 71px;
}
.logos1 {
    width: 150px;
    object-fit: cover;
    height: 40px;
}

.mobile-tab-ul {
    list-style: none;
    padding: 25px 22px;
    display: flex;
    gap: 35px;
    /* height: 50px; */
    flex-direction: column;
    justify-content: space-between;
}

.mobile-tab-ul > li {
    font-size: 18px;
    text-transform: uppercase;
}

.linko {
    color: white !important;
}

.sc-beqWaB {
    /* background: linear-gradient(190deg, #072342, black) !important; */
}
.active-link {
    border-bottom: 2px solid blue;
}

/* color: black;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 35px;
} */

.sub-menu-link {
    text-transform: uppercase;
}
.ul-pros {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px 0px 0px 0px;
}
.row11 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}
.di-12 {
    display: flex;
    gap: 20px;
    align-items: center;
}
.updown-spa > svg {
    font-size: 25px;
}

.bg-img.bg-img-top {
    background-position: center !important;
}

    .logi2i {
        width: 131px;
        padding: 10px 0px;
        height: 100px;
        object-fit: cover;
    }

.intro-section {
    background-position: center !important;
}
.inj1 {
    object-fit: cover;
    width: 350px;
    height: 300px;
    border-radius: 10px;
}
.head-tit {
    color: black;
    font-weight: 700;
    font-size: 25px;
    padding: 15px 0px;
    text-transform: uppercase;
}
.bb-b1 {
    border: 1px solid;
    border-radius: 15px;
    padding: 10px 10px;
}
.bb-b2 {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 10px;
}
.bb-b3 {
    display: flex;
    flex-direction: column;
}

.head-pro4 {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 500px;
    padding: 15px;
    display: flex;
    border-radius: 15px;
    gap: 14px;
    flex-direction: column;
}

.head-di1 {
    display: flex;
    border: 1px solid #ada4a4;
    padding: 5px 10px;
    justify-content: space-between;
}
.head-di2 {
    display: flex;
    flex-direction: column;
}
.pre-pre {
    white-space: pre;
}

.brim-ss{

    width: 190px;
}
.brim-logo-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 30px;

}
.bb-pic{
    width: 1000px;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.bb-top-d{
    display: flex;
    flex-direction: column;
    align-content: center;
    gap: 20px;
    align-items: center;

}
.d-wl{
    width: 100%;
    /* align-items: center; */
    display: flex;
    /* align-items: center; */
    justify-content: center;
}
.d-w1{
    width: 95%;
    
}


.hea-ci{

    color: #00B1E7;
    font-weight: 700;
    font-size: 55px;
    text-transform: uppercase;
}
.dd-io{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0px 20px 0px ;
}
.bb-none1{

    background-color: transparent;
    border: 0;
    color: inherit;
    cursor: pointer;
    outline: 0!important;
}
.bb-none1{

background-color: 
#00B1E7;

color: white;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
padding: 10px;
border-radius: 6px;
}


@media only screen and (max-width: 600px) {

.hea-ci{

font-size: 31px;

}
.dd-io{

    padding: 50px 0px 20px 0px ;
}

}
.contact-form-wrapper {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;  
    border-radius: 5px;

}
.bb-shadow{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 5px;

}
@media only screen and (max-width: 990px) {

    .logoss {
        width: 69px !important;
        object-fit: cover;
        height: 40px !important;
    }
}


.head-con {
    padding: 80px 10px !important;
    background-color: black;
}