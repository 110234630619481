.content-head-home {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: black;
    padding: 80px 50px;
    text-align: center;
}
.top-h1{
color: white;
font-size: 20px;
}
.top-p1 {
    width: 800px;
    font-size: 18px;
    text-align: center;
}
.check-auth{
    background-color: white;
    padding: 10px 25px;
    border-radius: 20px;
    border: 0;
    color: black;
    font-weight: bold;
    outline: 0!important;
}
.check-auth:hover{
    background-color: #F3622D;
    color: white;


}

@media only screen and (max-width: 900px) {
    .top-p1 {
        width: unset !important;
        font-size: 18px;
        text-align: center;
    }


}