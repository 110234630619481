
  .contact-us-sec{
   position: relative;
    display: block;
    width: 380px;
    height: auto;
    background: #35333352;
    padding: 40px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 15px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  }
.contact-us #contact {
    position: relative;
    display: block;
    width: 380px;
    height: auto;
    background: #35333352;
    padding: 40px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 15px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  }
  .contact-us-sec{
    position: relative; 
    display: block;
    margin: auto;
  }
.contact-us .section-heading {
    position: relative; 
    display: block;
    margin: auto;
  }
  
  .section-heading h4 {
    line-height: 40px;
    font-size: 28px;
    font-weight: 900;
    color:#F3622D;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 40px;
  }
  
.input-contact1{
    width: 100%;
    position: relative;
    display: block;
    background-color: #f4f7fb;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: 500;
    border: none;
    box-shadow: none;
    border-radius: 5px;
    outline-color: #9e9e9e;
  }
  .text-area-c{
    width: 100%;
    position: relative;
    display: block;
    background-color: #f4f7fb;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: 500;
    border: none;
    box-shadow: none;
    border-radius: 5px;
    outline-color: #9e9e9e;
  }
  
  .input-contact1{
    height: 40px;
    padding: 0px 15px;
  }
  
  .text-area-c {
    min-height: 140px;
    max-height: 180px;
    padding: 15px;
    resize: none;
  }
  
  .contact-us span {
    height: 20px;
      font-size: 12px;
    margin-bottom: 20px;
  }
  
  .valid_info_name, .valid_info_email, .valid_info_message{
    display: inline-block;
    font-size: 13px;
    margin: 5px 2px;
  }
  
  .valid {
    border: 2px solid green;
    outline-color: green;
  }
  
  .invalid {
    border: 2px solid red;
    outline-color: red;
  }
  
 

  


@media only screen and (max-width: 600px) {

  .contact-us-sec {
width: 100% !important;
padding: 30px;
  }
}


.btn-sendfrom{
  border: 1px solid lightgray;
  background: transparent;
  color: white;
  padding: 10px 10px;
  border-radius: 5px;
}
.btn-div-send {
  display: flex;
  justify-content: center;
}

.head-con{
  padding: 50px 10px ;
  background-color: black;
}