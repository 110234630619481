.logo-head-2{
    width: 100px;
}
.head2-data {
    background-color: black;
    display: flex;
    justify-content: space-around;
    padding: 20px;
    align-items: center;
}

.head2-data2{
    background-color: black;
    display: flex;
    justify-content: space-around;
    padding: 20px;
    align-items: center;
    width: 100%;
    z-index: 10;
    position: fixed;
}

.fadeInDown1 {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    }
    @-webkit-keyframes fadeInDown {
    0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    }
    100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    }
    }
    @keyframes fadeInDown {
    0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    }
    100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    }
    } 

    @media only screen and (max-width: 700px) {


    .window-link{
        display: none;
    }
    .head2-data {
    
        justify-content: space-between;
    
    }
    .mobile-nav{
        display: unset !important;
    }
    .head2-data2 {
     
        justify-content: space-between;
    
    }

}
.mobile-nav{
    display: none;
}